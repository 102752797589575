<template>
  <div class="error_page">
    <img src="@/assets/image/error/404.png" class="error_img">
    <div class="error_font">404 not found</div>
    <el-button type="primary" class="error_btn" @click="$router.push('/homePage')">返回首页</el-button>
  </div>
</template>

<script>
export default {
  name:'errorPage'
}
</script>

<style scoped>
.error_page{
  height: 100vh;
  background-image: url('../assets/image/error/errorPage.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 2.28rem;
}
.error_img{
  width: 136px;
  height: 69px;
}
.error_font{
  color: #9097AC;
  font-size: 14px;
  margin: 18px 0;
}
.error_btn{
  width: 194px;
  height: 40px;
}
</style>